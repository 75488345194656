import React from "react";
import Layout from "../components/Layout";
import styled from 'styled-components';
import bgImage from '../assets/images/callcenter.jpg';
import officeImage from '../assets/images/office.jpg';
import vancouverImage from '../assets/images/vancouver.jpg';

const ContactStyles = styled.div`
  
  padding: 100px 40px 20px 40px;
    
  :before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.125;
    background-image: url(${bgImage});
    background-repeat: repeat;
    background-position: 50% 0;
    background-size: cover;
    background-attachment: fixed;
  }

  .medium {
    font-family: Roboto-Medium;
  }

  img {
    max-height: 200px;
    height: 100%;
    width: auto;
  }

  @media screen and (max-width: 1000px) {
    padding: 100px 20px 20px 20px;
  }
`;

export default function Contact() {
  return <Layout>
    <ContactStyles>
      <img src={officeImage} alt='office' />
      <p className='medium'>Tel: <a href="tel:+16044601845">604-460-1845</a></p>
      <p className='medium'>Fax: <a href="tel:+16044608637">604-460-8637</a></p>

      <p><span className='medium'>General Inquiries: <a href="mailto:info@transnetmultilingual.ca">info@transnetmultilingual.ca</a></span><br/>(including Human Resources)</p>
      <p className='medium'>Quotes: <a href="mailto:quotes@transnetmultilingual.ca">quotes@transnetmultilingual.ca</a></p>
      <p className='medium'>Production: <a href="mailto:gskitsko@transnetmultilingual.ca">gskitsko@transnetmultilingual.ca</a></p>
      <p className='medium'>Accounting: <a href="mailto:cbeck@transnetmultilingual.ca">cbeck@transnetmultilingual.ca</a></p>

      <img src={vancouverImage} alt='Vancouver cityscape' />
      <p className='medium'>Our office is located in the beautiful Vancouver, B.C. area</p>
    </ContactStyles>
  </Layout>
}
